if (
  window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: dark)').matches
) {
  //document.body.classList.add('dark');
  console.log('activating dark mode');
}

// ===== AUDIO =====
let chimesPlayed = 0;
var audio = new Audio('chime-1.wav');

function play() {
  audio.play();
  chimesPlayed += 1;
  console.log(`sound played ${chimesPlayed}`);
}

// ===== FUNCTIONALITY =====
const INTERVAL_IN_MINUTES = 10;
const INTERVAL_IN_MILLISECONDS = INTERVAL_IN_MINUTES * 60 * 1000;
let timerId;

const intervalSpan = document.getElementById('interval-span');
intervalSpan.textContent = INTERVAL_IN_MINUTES;

const toggleButton = document.getElementById('toggle-btn');

function togglePlay() {
  if (timerId) {
    clearInterval(timerId);
    timerId = null;
    console.log(`timer cleared`);
    toggleButton.textContent = 'PLAY';
  } else {
    timerId = setInterval(() => play(), INTERVAL_IN_MILLISECONDS);
    console.log(`timer set`);
    toggleButton.textContent = 'STOP';
  }
}
